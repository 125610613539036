<template>
	<div class="products container mx-auto px-8 lg:px-0">
		<div class="products-top-block flex flex-col lg:flex-none lg:grid grid-rows-1 grid-cols-5 gap-4 lg:px-0">
			<div class="products-top-block-left lg:col-span-2 xl:col-span-1 flex flex-col justify-center lg:justify-between relative order-2 lg:order-first pt-4 lg:pt-0">
				<template v-if="activeThemeIndex == 0">
					<img
						:src="cover.images ? cover.images[0]?.url : ''"
						alt=""
					>
				</template>
				<template v-if="activeThemeIndex == 1">
					<img
						:src="cover2.images ? cover2.images[0]?.url : ''"
						alt=""
					>
				</template>
				<template v-if="activeThemeIndex == 2">
					<img
						:src="cover3.images ? cover3.images[0]?.url : ''"
						alt=""
					>
				</template>
				<div class="products-header absolute text-secondary lg:bottom-0 flex flex-col text-left font-bold font-header p-8 lg:h-72 w-full items-center justify-start">
					<div>
						<h1
							class="text-lg leading-loose tracking-wider"
							v-html="header.title"
						></h1>
						<p>-</p>
						<h1
							class="text-lg leading-loose tracking-wider"
							v-html="header.subTitle"
						>
						</h1>
						<!-- <p
							class="text-base font-normal leading-loose tracking-widest pt-6 font-body"
							v-html="header.description"
							v-if="header.description"
						></p> -->
					</div>
				</div>
			</div>
			<div class="products-top-block-right lg:col-span-3 xl:col-span-4 relative">
				<!-- top swiper -->
				<swiper
					:slides-per-view="1"
					:space-between="0"
					:centered-slides="true"
					:centered-slides-bounds="true"
					:autoplay="{
  'delay': 5000,
'disableOnInteraction': false
}"
					@swiper="setControlledSwiper"
					@slideChange="onSlideChange"
				>
					<template v-if="activeThemeIndex == 0">

						<swiper-slide
							v-for="image in slider1?.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</template>
					<template v-if="activeThemeIndex == 1">
						<swiper-slide
							v-for="image in slider1a?.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</template>
					<template v-if="activeThemeIndex == 2">
						<swiper-slide
							v-for="image in slider1b?.images"
							:key="image.id"
						>
							<img
								:src="image.url"
								alt=""
							>
						</swiper-slide>
					</template>
				</swiper>
				<bullets
					:count="slider1?.images?.length"
					:activeIndex="activeIndex"
					:color="themeColor"
					@bulletIndex="slideTo"
					class="flex absolute -right-0 -bottom-6"
				></bullets>
			</div>
		</div>
		<div class="products-bottom-block pt-12 lg:pt-36">
			<div class="hidden lg:block products-header container mx-auto relative">
				<div class="header-text text-left text-2xl font-title font-normal leading-tight tracking-wide absolute top-0 left-0 z-10 mt-6 ml-6">
					<p>-</p>
					<h1
						class="header-text-top text-left "
						:class="`text-${themeColor}`"
					>Cultural and</h1>
					<h1 class="header-text-bottom text-left text-secondary">Creative</h1>
				</div>
			</div>
			<div class="products-content flex flex-col lg:flex-none lg:grid grid-cols-14 grid-rows-1 h-full lg:px-0">
				<div class="products-content-slider-left col-span-8 h-full relative">
					<!-- top swiper -->
					<swiper
						:slides-per-view="1"
						:space-between="0"
						:centered-slides="true"
						:centered-slides-bounds="true"
						:autoplay="{
  'delay': 5000,
'disableOnInteraction': false
}"
						@swiper="setControlledSwiper2"
						@slideChange="onSlide2Change"
					>
						<template v-if="activeThemeIndex == 0">

							<swiper-slide
								v-for="image in slider2?.images"
								:key="image.id"
							>
								<img
									:src="image.url"
									alt=""
								>
							</swiper-slide>
						</template>
						<template v-if="activeThemeIndex == 1">

							<swiper-slide
								v-for="image in slider2a?.images"
								:key="image.id"
							>
								<img
									:src="image.url"
									alt=""
								>
							</swiper-slide>
						</template>
						<template v-if="activeThemeIndex == 2">
							<swiper-slide
								v-for="image in slider2b?.images"
								:key="image.id"
							>
								<img
									:src="image.url"
									alt=""
								>
							</swiper-slide>
						</template>
					</swiper>
					<bullets
						:count="slider2?.images?.length"
						:activeIndex="activeIndex2"
						:color="themeColor"
						@bulletIndex="slide2To"
						class="flex absolute -right-0 -bottom-6 z-10"
					></bullets>
				</div>
				<div class="content-text col-start-10 col-span-5 flex flex-col h-full text-left py-4 lg:py-0  justify-between w-full">
					<div class="products-plan">
						<div
							class="plan flex flex-col"
							v-for="item in plan?.items"
							:key="item.id"
						>
							<template v-if="item.name == theme.name">
								<div class="plan-content mt-8 lg:mt-0 flex flex-col justify-center font-body tracking-widest leading-loose">
									<h2
										class="content-subtitle text-left font-bold text-lg "
										:class="`content-title-color-${themeColor}`"
									>{{ plan.title }}</h2>
									<h2 class="content-title text-left font-bold text-xl">{{ plan.description }} <span :class="`content-title-color content-title-color-${themeColor}`">【{{ item.name }}】</span></h2>
									<h1 class="content-price text-left text-2xl font-bold"><span class="text-2xl">$</span> {{ item.price }} </h1>
									<p
										class="content-description text-left text-secondary text-md"
										v-html="item.contents"
									></p>
								</div>
							</template>
						</div>

					</div>
					<div class="products-spec mt-8 lg:mt-0 grid grid-flow-row grid-cols-6 grid-rows-2 font-body tracking-wider">
						<!-- <div class="spec-buy col-span-7 row-span-2 flex justify-center items-center">
							<a :href="spec.title">
								<el-button
									class="spec-button spec-button-buy text-md"
									icon="el-icon-shopping-bag-1"
									:class="`spec-button-${themeColor}`"
								>
									選購請聯絡我們
								</el-button>
							</a>
						</div>
						<div class="spec-name col-span-1 row-span-2">色系</div>
						<div class="spec-info col-span-4 row-span-2">{{ spec.subTitle }}</div>
						<div class="spec-name col-span-1 row-span-2">紙質</div>
						<div class="spec-ingredient col-span-1 row-span-2 text-md">{{ spec.description }}</div> -->
						<!-- <div class="spec-name col-span-1 row-span-2"></div> -->
						<div class="spec-colors col-span-6 row-span-2"><button
								v-for="color in colors"
								:key="color.id"
								class="spec-button"
								:class="`spec-button-${color.color}`"
								@click="onChangeThemeColor(color.color)"
							>{{color.name}}款</button></div>
						<!-- <div class="spec-name col-span-1 row-span-1">尺寸</div>
						<div class="spec-size col-span-6 row-span-1">{{ spec.subDescription }}</div> -->
					</div>

				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { fetchData } from "../utils/fetchApi";
import SwiperCore, {
	Navigation,
	Pagination,
	Autoplay,
	Controller,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Bullets from "../components/Bullets.vue";
import { productsColors } from "../utils/productsColors";

SwiperCore.use([Navigation, Pagination, Autoplay, Controller]);

export default {
	components: {
		Swiper,
		SwiperSlide,
		Bullets,
	},
	setup() {
		const store = useStore();
		const enabledMenuId = store.state.enabledMenuId;
		// define all refs of blocks id
		const coverId = ref(null);
		const cover2Id = ref(null);
		const cover3Id = ref(null);
		const headerId = ref(null);
		const slider1Id = ref(null);
		const slider2Id = ref(null);
		const slider1aId = ref(null);
		const slider2aId = ref(null);
		const slider1bId = ref(null);
		const slider2bId = ref(null);
		const specId = ref(null);
		const planId = ref(null);

		// define all forms reactive fields
		const cover = reactive({});
		const cover2 = reactive({});
		const cover3 = reactive({});
		const header = reactive({});
		const slider1 = reactive({});
		const slider2 = reactive({});
		const slider1a = reactive({});
		const slider2a = reactive({});
		const slider1b = reactive({});
		const slider2b = reactive({});
		const spec = reactive({});
		const plan = reactive({});

		// set swiper instances ref
		const controlledSwiper = ref(null);
		const controlledSwiper2 = ref(null);

		const idTitleObjArray = [
			{ id: headerId, title: "orientalcard header" },
			{ id: coverId, title: "orientalcard purple cover" },
			{ id: slider1Id, title: "orientalcard purple display1" },
			{ id: slider2Id, title: "orientalcard purple display2" },
			{ id: cover2Id, title: "orientalcard red cover" },
			{ id: slider1aId, title: "orientalcard red display1" },
			{ id: slider2aId, title: "orientalcard red display2" },
			{ id: cover3Id, title: "orientalcard green cover" },
			{ id: slider1bId, title: "orientalcard green display1" },
			{ id: slider2bId, title: "orientalcard green display2" },
			{ id: planId, title: "orientalcard list" },
			{ id: specId, title: "orientalcard specification" },
		];

		// set @swiper instances
		const setControlledSwiper = (swiper) =>
			(controlledSwiper.value = swiper);
		const setControlledSwiper2 = (swiper) =>
			(controlledSwiper2.value = swiper);

		// set Bullets slide to @bulletsIndex
		const slideTo = (index) => {
			controlledSwiper.value.slideTo(index);
		};
		const slide2To = (index) => {
			controlledSwiper2.value.slideTo(index);
		};

		// set current slide index on <swiper>
		const activeIndex = ref(0);
		const activeIndex2 = ref(0);
		const onSlideChange = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeIndex.value = swiper.activeIndex;
		};
		const onSlide2Change = (swiper) => {
			if (swiper.activeIndex == null) return;
			activeIndex2.value = swiper.activeIndex;
		};

		const numberWithCommas = (num) => {
			return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		};

		const colors = productsColors.orientalcard;

		// define theme color
		const themeColor = ref("purple");
		const theme = ref(colors[0]);
		const activeThemeIndex = ref(0);

		const onChangeThemeColor = (color) => {
			const activeTheme = colors.find((c) => c.color == color);
			theme.value = activeTheme;
			themeColor.value = activeTheme.color;
			activeThemeIndex.value = colors.indexOf(activeTheme);
		};

		onMounted(async () => {
			await fetchData.menuBlocks(enabledMenuId, idTitleObjArray);

			await fetchData.slider(coverId, cover);
			await fetchData.slider(cover2Id, cover2);
			await fetchData.slider(cover3Id, cover3);
			await fetchData.content(headerId, header);
			await fetchData.slider(slider1Id, slider1);
			await fetchData.slider(slider2Id, slider2);
			await fetchData.slider(slider1aId, slider1a);
			await fetchData.slider(slider2aId, slider2a);
			await fetchData.slider(slider1bId, slider1b);
			await fetchData.slider(slider2bId, slider2b);
			await fetchData.content(specId, spec);
			await fetchData.priceList(planId, plan);
		});

		return {
			cover,
			cover2,
			cover3,
			header,
			slider1,
			slider2,
			slider1a,
			slider2a,
			slider1b,
			slider2b,
			spec,
			plan,
			activeIndex,
			activeIndex2,
			controlledSwiper,
			controlledSwiper2,
			setControlledSwiper,
			setControlledSwiper2,
			slideTo,
			slide2To,
			onSlideChange,
			onSlide2Change,
			numberWithCommas,
			colors,
			theme,
			themeColor,
			onChangeThemeColor,
			activeThemeIndex,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/styles.scss";

.products {
	&-top-block {
		height: 40rem;
		@media (max-width: 1024px) {
			height: auto;
		}
		&-left {
			width: 100%;
			height: 100%;
			@media (max-width: 1024px) {
				height: 15rem;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top;
				@media (max-width: 1024px) {
					object-position: bottom;
				}
			}
		}
	}
	&-bottom-block {
		height: auto;
	}
	&-spec {
		border: 1px solid $gray;
		background: $white;
		& > div {
			padding-top: 0.1rem;
			padding-bottom: 0.1rem;
			border: 0.5px solid $gray;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.spec {
	&-button {
		border-radius: 5px;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		@apply font-body tracking-widest;
		&-purple {
			color: $white;
			background: $purple;
		}
		&-red {
			color: $white;
			background: $red2;
		}
		&-green {
			color: $white;
			background: $green;
		}
		&-buy {
			border-radius: 0;
			border: 2px solid $about;
		}
	}
}

.products-spec {
	& > * {
		@apply font-body tracking-wider;
	}
}
.content-title {
	&-color {
		&-red {
			color: $red2;
		}
		&-purple {
			color: $purple;
		}
		&-green {
			color: $green;
		}
	}
}
.swiper-container {
	height: 100%;
}
.swiper-slide {
	// width: 80% !important;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.swiper-wrapper {
	height: 100% !important;
	align-items: center !important;
}
.spec {
	&-info,
	&-size,
	&-ingredient {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
}
</style>